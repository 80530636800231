import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardProps } from "./cards-content.interface"
import styled from "styled-components"
import { layout, LayoutProps, space, SpaceProps } from "styled-system"
import theme from "../../theming/theme"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import {
  CardTypographyBottom,
  CardTypographyTop,
} from "./card-typography.component"

const Root = styled.div<SpaceProps & LayoutProps>`
  ${space};
  ${layout};
  margin-bottom: ${theme.space.xl};
  margin-left: auto;
  margin-right: auto;
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;

    &:nth-child(even) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-left: 0;
    }
  }
`

const Box = styled.div<SpaceProps>`
  ${space};
`

const Image = styled(GatsbyImage)<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`

const CardAlignLeft: AppFunctionComponent<CardProps> = ({
  image,
  paragraph,
  title,
}) => {
  return (
    <Root
      maxWidth={[null, null, "580px"]}
      width={[null, null, "calc(50% - 20px)"]}
    >
      <Image
        fluid={image.fluid}
        height={["80px", null, "96px"]}
        width={["110px", null, "127px"]}
        my={["m", null, "l"]}
        ml={["8px", null, null, "82px"]}
        objectFit={"contain"}
      />
      <Box px={["s", null, "m", "100px"]} pb={["48px", null, "40px"]}>
        <CardTypographyTop mt={["zero"]}>{title}</CardTypographyTop>
        <CardTypographyBottom>{paragraph}</CardTypographyBottom>
      </Box>
    </Root>
  )
}

export default CardAlignLeft
