import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"
import CardSimple from "./card-simple.component"
import Typography from "../typography/typography.component"

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
`

const CardsWithDisclaimerSimple: AppFunctionComponent<CardsContent> = ({
  cards,
  disclaimerLeft,
}) => {
  return (
    <Box px={"s"}>
      <Box
        display={["block", "flex"]}
        justifyContent={"center"}
        flexWrap={[null, "wrap", null, "unset"]}
      >
        {cards.map((card, index) => {
          const { image, title, paragraph } = card
          return (
            <CardSimple
              image={image}
              paragraph={paragraph}
              title={title}
              key={index}
            />
          )
        })}
      </Box>
      {disclaimerLeft && (
        <Typography
          fontSize={["12px"]}
          lineHeight={"1.67"}
          color={"brownGrey"}
          textAlign={["center", null, "left"]}
          maxWidth={[null, null, "685px"]}
          mb={"zero"}
          mt={"xl"}
        >
          {disclaimerLeft}
        </Typography>
      )}
    </Box>
  )
}

export default CardsWithDisclaimerSimple
