import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"
import Typography from "../typography/typography.component"
import CardPlain from "./card-plain.component"

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
`

const CardsWithDisclaimer: AppFunctionComponent<CardsContent> = ({
  cards,
  disclaimerLeft,
  disclaimerRight,
}) => {
  return (
    <Box px={"s"} pb={["80px"]} maxWidth={"1200px"} mx={"auto"}>
      <Box display={["block", "flex"]} justifyContent={"center"}>
        {cards.map((card, index) => {
          const { image, title, paragraph } = card
          return (
            <CardPlain
              image={image}
              paragraph={paragraph}
              title={title}
              key={index}
            />
          )
        })}
      </Box>
      <Box
        display={["block", null, "flex"]}
        justifyContent={[null, null, "space-between"]}
      >
        {disclaimerLeft && (
          <Typography
            fontSize={["14px", null, "16px"]}
            mr={[null, null, "20px"]}
            lineHeight={"1.67"}
            opacity={0.5}
            maxWidth={[null, null, "580px"]}
            mb={"zero"}
            mt={"xl"}
            fontStyle={"italic"}
          >
            {disclaimerLeft}
          </Typography>
        )}
        {disclaimerRight && (
          <Typography
            fontSize={["14px", null, "16px"]}
            ml={[null, null, "20px"]}
            fontStyle={"italic"}
            lineHeight={"1.67"}
            opacity={0.5}
            maxWidth={[null, null, "580px"]}
            mb={"zero"}
            mt={["30px", null, "xl"]}
          >
            {disclaimerRight}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default CardsWithDisclaimer
