import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import CardAlignLeft from "./card-align-left.component"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"

const Root = styled.div<LayoutProps & SpaceProps & FlexboxProps>`
  ${layout};
  ${space};
  ${flexbox};
`

const CardsAlignLeft: AppFunctionComponent<CardsContent> = ({ cards }) => {
  return (
    <Root
      maxWidth={"1200px"}
      px={"s"}
      mx={"auto"}
      display={"flex"}
      flexDirection={["column", null, "row"]}
      flexWrap={[null, null, "wrap"]}
    >
      {cards.map((card, index) => {
        const { image, title, paragraph } = card
        return (
          <CardAlignLeft
            image={image}
            paragraph={paragraph}
            title={title}
            key={index}
          />
        )
      })}
    </Root>
  )
}

export default CardsAlignLeft
