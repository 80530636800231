import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { FeatureContent } from "./features-content.interface"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

const Root = styled.div<LayoutProps & FlexboxProps & SpaceProps>`
  ${layout};
  ${flexbox};
  ${space};
`

const Box = styled.div<LayoutProps & FlexboxProps & SpaceProps>`
  ${layout};
  ${flexbox};
  ${space};
  box-sizing: border-box;
`

const Typography = styled.p<TypographyProps & SpaceProps>`
  ${typography};
  ${space};
`

const Feature: AppFunctionComponent<FeatureContent> = ({
  image,
  position,
  text,
}) => {
  return (
    <Root
      px={["s", null, "l", null, "zero"]}
      maxWidth={"1200px"}
      mx={"auto"}
      pb={["16px", null, "xxl"]}
      display={"flex"}
      justifyContent={[null, null, "space-between"]}
      flexDirection={[
        "column",
        null,
        position === "left" ? "row" : "row-reverse",
      ]}
    >
      <Box
        width={["100%", null, "40%", null, "calc(57% + 100px)"]}
        ml={[null, null, null, null, position === "left" ? "-100px" : null]}
        mr={[null, null, null, null, position === "right" ? "-100px" : null]}
      >
        <GatsbyImage fluid={image.fluid} />
      </Box>
      <Box
        width={["100%", null, "50%", null, "43%"]}
        maxWidth={[null, null, "480px"]}
        display={["block", null, "flex"]}
        justifyContent={[null, null, "center"]}
        flexDirection={[null, null, "column"]}
      >
        <Typography
          textAlign={["center", null, "left"]}
          mb={["s"]}
          mt={["m", null, "zero"]}
          fontSize={["heading2-mobile", null, "heading2"]}
          letterSpacing={["heading2-mobile", null, "heading2"]}
          lineHeight={["1.43"]}
          fontWeight={"900"}
        >
          {text.title.title}
        </Typography>
        {text.subtitle && (
          <Typography
            mt={["zero"]}
            mb={["xl", null, "zero"]}
            fontSize={["body2"]}
            lineHeight={["body"]}
            textAlign={["center", null, "left"]}
          >
            {text.subtitle.subtitle}
          </Typography>
        )}
      </Box>
    </Root>
  )
}

export default Feature
