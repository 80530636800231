import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeroContent } from "./hero-content.interface"
import GatsbyImage from "gatsby-image/index"
import Button from "../base/button/button.component"
import styled from "styled-components"
import {
  typography,
  TypographyProps,
  color,
  ColorProps,
  SpaceProps,
  space,
  layout,
  LayoutProps,
  position,
  PositionProps,
  flexbox,
  FlexboxProps,
} from "styled-system"
import theme from "../../theming/theme"
import text from "../../functions/text"

const Box = styled.div<LayoutProps & SpaceProps & PositionProps & FlexboxProps>`
  ${layout}
  ${space}
  ${position}
  ${flexbox}
`

const StyledBox = styled.div<
  LayoutProps & SpaceProps & PositionProps & FlexboxProps
>`
  z-index: 1;
  ${layout}
  ${space}
  ${position}
  ${flexbox}
`

const Typography = styled.p<TypographyProps & ColorProps & SpaceProps>`
  ${typography}
  ${color}
  ${space}
`

const Image = styled(GatsbyImage)<LayoutProps>`
  ${layout}
`

const Video = styled.video<LayoutProps & PositionProps & SpaceProps>`
  width: ${theme.sizes.video1};
  max-height: 690px;

  @media (min-width: ${theme.breakpoints[3]}) {
      width: ${theme.sizes.video2};
  }

  ${layout}
  ${position}
  ${space}
`

const HeroUI: AppFunctionComponent<
  HeroContent & {
    videoMp4: { publicURL: string }
    videoWebm: { publicURL: string }
  }
> = ({ herotext, herobutton, background, videoMp4, videoWebm }) => {
  return (
    <Box
      position={"relative"}
      maxWidth={"1600px"}
      mx={"auto"}
      overflow={"hidden"}
    >
      <Box
        display={["block", null, "flex"]}
        maxWidth={"1200px"}
        mx={"auto"}
        flexDirection={"row-reverse"}
        pt={["xxl", null, "180px"]}
        px={"s"}
        pb={["xxl", null, "zero"]}
      >
        <Video
          muted
          autoPlay
          display={["none", null, "block"]}
          mr={[null, null, null, null, "-200px"]}
        >
          <source src={`${videoWebm.publicURL}`} type="video/webm" />
          <source src={`${videoMp4.publicURL}`} type="video/mp4" />
        </Video>
        <StyledBox
          width={[null, null, "40%"]}
          ml={[null, null, null, null, "-200px"]}
        >
          <Typography
            as={"h1"}
            mt={"zero"}
            mr={[null, null, "-180px"]}
            mb={["s", null, "l"]}
            fontSize={["heading1-mobile", null, "heading1"]}
            fontWeight={"900"}
            lineHeight={["1.3", null, "heading"]}
            letterSpacing={["heading1-mobile", null, "heading1"]}
            textAlign={["center", null, "left"]}
          >
            {text(herotext.title.title)}
          </Typography>
          {herotext.subtitle && (
            <Typography
              fontWeight={"300"}
              as={"p"}
              mt={"zero"}
              mb={["l", null, "xl"]}
              lineHeight={"body"}
              fontSize={"body"}
              textAlign={["center", null, "left"]}
            >
              {text(herotext.subtitle.subtitle)}
            </Typography>
          )}
          <Box display={["none", null, "block"]} pb={"l"}>
            <Button text={herobutton.text} url={herobutton.url} />
          </Box>
        </StyledBox>
        <Image
          display={["block", null, "none"]}
          fluid={background.fluid}
          width={"100%"}
        />
      </Box>
    </Box>
  )
}

export default HeroUI
