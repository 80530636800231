import { css } from "styled-components"

export const ButtonStyle = css`
  clip-path: polygon(0% 46%, 9% 0%, 100% 0%, 100% 64%, 91% 100%, 0% 100%);
  width: 270px;
  height: 64px;
  text-decoration: none;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease-in;
  background-color: #79f2be;
  background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
  background-repeat: no-repeat;

  &:hover {
    transition: background 0.3s ease-in;
    background-position: 300px;
  }
`
