import styled from "styled-components"
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

const Typography = styled.p<
  TypographyProps & SpaceProps & LayoutProps & ColorProps & PositionProps
>`
  ${typography};
  ${space};
  ${layout};
  ${color};
  ${position};
`

export default Typography
