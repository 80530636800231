import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingContent } from "./heading-content.interface"
import styled from "styled-components"
import {
  typography,
  TypographyProps,
  space,
  SpaceProps,
  FlexboxProps,
  LayoutProps,
  flexbox,
  layout,
} from "styled-system"
import ArrowIcon from "../../images/arrow.svg"
import { renderAst } from "../../functions/renderer-ast"

const Root = styled.div<
  SpaceProps & FlexboxProps & LayoutProps & TypographyProps
>`
  ${space};
  ${flexbox};
  ${layout};
  ${typography}
`

const Typography = styled.div<TypographyProps & SpaceProps>`
  ${typography}
  ${space}
`
const Box = styled.div<
  FlexboxProps & LayoutProps & TypographyProps & SpaceProps
>`
  ${layout}
  ${flexbox}
  ${typography}
  ${space}
`

const HeadingArrow: AppFunctionComponent<HeadingContent> = ({
  title,
  subtitle,
}) => {
  return (
    <Root
      mx={"auto"}
      maxWidth={"1200px"}
      pt={["120px"]}
      display={[null, null, "flex"]}
      textAlign={["center", null, "left"]}
      pb={["80px"]}
      px={"s"}
      justifyContent={[null, null, "space-between"]}
      alignItems={[null, null, "center"]}
    >
      <Box
        maxWidth={[null, "380px", null, "480px"]}
        mx={["auto", null, "zero"]}
      >
        <Typography
          fontSize={["heading1-mobile", null, "heading1"]}
          mt={"zero"}
          mb={"40px"}
          fontWeight={"900"}
          letterSpacing={["heading1-mobile", null, "heading1"]}
        >
          {renderAst(title.childMarkdownRemark.htmlAst)}
        </Typography>
        {subtitle && (
          <Typography fontSize={["body"]} lineHeight={["body"]}>
            {renderAst(subtitle.childMarkdownRemark.htmlAst)}
          </Typography>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={["column-reverse", null, "row"]}
        alignItems={["center", null, "start"]}
        mr={[null, null, "60px"]}
      >
        <ArrowIcon />
        <Typography fontSize={["body2"]} ml={[null, null, "30px"]} my={"zero"}>
          Zobacz, jak może wyglądać
          <br />
          <strong>Twój Hub Mobilności</strong>
        </Typography>
      </Box>
    </Root>
  )
}

export default HeadingArrow
