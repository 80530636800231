import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { ButtonContent } from "./button-content.interface"
import { Link } from "gatsby"
import styled from "styled-components"
import {
  color,
  ColorProps,
  background,
  BackgroundProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
} from "styled-system"
import { ButtonStyle } from "./button.style"

const Root = styled(Link)<
  ColorProps & BackgroundProps & FlexboxProps & TypographyProps & LayoutProps
>`
  ${color};
  ${background};
  ${flexbox};
  ${typography};
  ${layout};
  ${ButtonStyle};
`

const Button: AppFunctionComponent<ButtonContent> = ({
  text,
  url,
  className,
}) => {
  return (
    <Root
      className={className}
      color={"dark"}
      fontWeight={"bold"}
      fontSize={"body"}
      letterSpacing={"button"}
      display={"flex"}
      to={url}
    >
      {text}
    </Root>
  )
}

export default Button
