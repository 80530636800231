import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { AllModels } from "../types/data-model.interface"
import { ModelType } from "../types/model-type.enum"
import HeadingSwitch from "../components/heading/heading.switch"
import Section from "../components/section/section.component"
import Hero from "../components/hero/hero.component"
import CardsSwitch from "../components/cards/cards-switch.component"
import Features from "../components/features/features.component"
import CtaSwitch from "../components/cta-section/cta-section-switch.component"
import Contact from "../components/contact/contact.component"
import Image from "../components/image/image.component"
import HeadingWithCards from "../components/heading-with-cards/heading-with-cards.component"
import Document from "../components/document/document.component"
import Table from "../components/table/table.component"

interface Props {
  components: AllModels[]
}

const Resolver: AppFunctionComponent<Props> = ({ components = [] }) => {
  return (
    <>
      {components.map((component, index) => {
        component.$type = component.internal.type
        switch (component.$type) {
          case ModelType.Section:
            return <Section key={index} {...component} />
          case ModelType.Heading:
            return <HeadingSwitch key={index} {...component} />
          case ModelType.CtaSection:
            return <CtaSwitch key={index} {...component} />
          case ModelType.Hero:
            return <Hero key={index} {...component} />
          case ModelType.Cards:
            return <CardsSwitch key={index} {...component} />
          case ModelType.Features:
            return <Features key={index} {...component} />
          case ModelType.Contact:
            return <Contact {...component} key={index} />
          case ModelType.Image:
            return <Image {...component} key={index} />
          case ModelType.HeadingWithCards:
            return <HeadingWithCards {...component} key={index} />
          case ModelType.Document:
            return <Document {...component} key={index} />
          case ModelType.Table:
            return <Table {...component} key={index} />
          default:
            return null
        }
      })}
    </>
  )
}

export default Resolver
