import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import Layout from "../../layout/layout"
import Resolver from "../../resolver/resolver.component"
import { AllModels } from "../../types/data-model.interface"
import Header from "../base/header/header.component"
import { useNavigationLinks } from "../../hooks/use-navigations-links"
import Footer from "../base/footer/footer.component"
import Cookies from "../cookies/cookies.component"
import SEO from "../seo"
import styled from "styled-components"
import { layout, LayoutProps } from "styled-system"

interface Props {
  components: AllModels[]
  title: string
  image: { file: { url: string } }
}

const Main = styled.main<LayoutProps>`
  ${layout}
`

const Page: AppFunctionComponent<Props> = ({ components, image, title }) => {
  const { mainLinks, footerLinks } = useNavigationLinks()

  return (
    <Layout>
      <SEO title={title} image={image.file.url} />
      <Header links={mainLinks} />
      <Main overflowX="hidden">
        <Resolver components={components} />
      </Main>
      <Footer links={footerLinks} />
      <Cookies />
    </Layout>
  )
}

export default Page
