export enum ModelType {
  Empty = "",
  Heading = "ContentfulHeading",
  Section = "ContentfulSection",
  CtaSection = "ContentfulCtaSection",
  Hero = "ContentfulHero",
  Cards = "ContentfulCardsWrapper",
  Features = "ContentfulFeaturesWrapper",
  Contact = "ContentfulContact",
  Image = "ContentfulImage",
  HeadingWithCards = "ContentfulHeadingWithCards",
  Document = "ContentfulDocument",
  Table = "ContentfulTable",
}
