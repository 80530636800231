import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import styled from "styled-components"
import Resolver from "../../resolver/resolver.component"
import { SectionContent } from "./section-content.interface"
import { color, ColorProps } from "styled-system"

const Root = styled.section<ColorProps>`
  ${color}
`

const Section: AppFunctionComponent<SectionContent> = ({
  backgroundColor,
  components,
}) => {
  return (
    <Root
      bg={backgroundColor === "black" ? "dark" : "light"}
      color={backgroundColor !== "black" ? "dark" : "light"}
    >
      <Resolver components={components} />
    </Root>
  )
}

export default Section
