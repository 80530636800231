import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { DocumentContent } from "./document-content.interface"
import styled from "styled-components"
import { layout, LayoutProps, space, SpaceProps } from "styled-system"
import theme from "../../theming/theme"
import { renderAst } from "../../functions/renderer-ast"

const Root = styled.div<SpaceProps & LayoutProps>`
  ${space};
  ${layout};

  ul,
  ol {
    margin: 0;
    padding: 0 0 0 16px;
  }

  img {
    width: 100%;
  }

  h2 {
    margin-top: 80px;
    margin-bottom: ${theme.space.s};
    font-size: ${theme.fontSizes["heading2-mobile"]};
    font-weight: 900;
    line-height: 1.43;
    letter-spacing: -0.7px;

    @media (min-width: ${theme.breakpoints[2]}) {
      font-size: ${theme.fontSizes.heading2};
      line-height: 1.4;
      letter-spacing: -1px;
      margin-top: ${theme.space.xxl};
    }
  }

  h3 {
    margin-top: 80px;
    margin-bottom: ${theme.space.s};
    font-size: ${theme.fontSizes["heading3-mobile"]};
    font-weight: 900;
    line-height: 1.6;
    letter-spacing: -0.6px;

    @media (min-width: ${theme.breakpoints[2]}) {
      line-height: 1.4;
      letter-spacing: -0.6px;
    }
  }

  a {
    color: #05864d;
    text-decoration: none;
  }

  p,
  li {
    font-size: ${theme.fontSizes.body2};
    font-weight: 300;
    line-height: 1.71;
    margin-bottom: 16px;
    margin-top: 0;

    @media (min-width: ${theme.breakpoints[2]}) {
      font-size: ${theme.fontSizes.body};
      line-height: 1.75;
    }
  }
`

const Document: AppFunctionComponent<DocumentContent> = ({ content }) => {
  return (
    <Root maxWidth={"790px"} mx={"auto"} px={"s"} py={["80px", null, "120px"]}>
      {renderAst(content.childMarkdownRemark.htmlAst)}
    </Root>
  )
}

export default Document
