import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CtaSectionContent } from "./cta-section-content.interface"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import { FluidObject } from "gatsby-image"
import Button from "../base/button/button.component"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import theme from "../../theming/theme"
import Typography from "../typography/typography.component"

const Root = styled.div<
  SpaceProps & TypographyProps & LayoutProps & FlexboxProps & PositionProps
>`
  ${space};
  ${typography};
  ${layout};
  ${flexbox};
  ${position};
`

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${theme.breakpoints[1]}) {
    width: 270px;
  }
`

const Box = styled.div<FlexboxProps & LayoutProps & SpaceProps & PositionProps>`
  ${flexbox};
  ${layout};
  ${space};
  ${position};
`

const Shape = styled(GatsbyImage)<LayoutProps & PositionProps>`
  ${layout}
  ${position}
`

const CtaWithShapes: AppFunctionComponent<
  CtaSectionContent & { shape1: FluidObject; shape2: FluidObject }
> = ({ button, subText, text, shape1, shape2 }) => {
  return (
    <Root
      textAlign={["center"]}
      pb={["80px"]}
      pt={["120px"]}
      overflow="hidden"
      position={"relative"}
    >
      <Box
        position={"absolute"}
        right={["-15px", null, "-70px"]}
        height={["80px", null, "300px"]}
        width={["100px", null, "300px"]}
        top={["50px", null, "100px"]}
      >
        <Shape
          objectFit="contain"
          fluid={shape1}
          height={["80px", null, "300px"]}
          width={["100px", null, "300px"]}
        />
      </Box>
      <Box
        top={["8px", null, "auto"]}
        bottom={[null, null, "-150px"]}
        position={"absolute"}
        left={["0px", null, "-50px"]}
        height={["80px", null, "319px"]}
        width={["48px", null, "230px"]}
      >
        <Shape
          objectFit="contain"
          fluid={shape2}
          height={["80px", null, "319px"]}
          width={["48px", null, "230px"]}
        />
      </Box>
      <Box
        mx={"auto"}
        maxWidth={["1200px"]}
        display={["flex"]}
        flexDirection={["column"]}
        alignItems={["center"]}
        px={"s"}
      >
        <Typography
          mt={["xl", null, "zero"]}
          fontSize={["heading2-mobile", null, "heading2"]}
          fontWeight={"900"}
          letterSpacing={["heading2-mobile", null, "heading2"]}
          mb={["s", null, "m"]}
        >
          {text}
        </Typography>
        <Typography
          mt={"zero"}
          mb={["xl"]}
          fontSize={["body2", null, "body"]}
          lineHeight={"body"}
        >
          {subText}
        </Typography>
        <StyledButton text={button.text} url={button.url} />
      </Box>
    </Root>
  )
}

export default CtaWithShapes
