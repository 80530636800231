import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardProps } from "./cards-content.interface"
import GatsbyImage from "gatsby-image/index"
import styled from "styled-components"
import { position, PositionProps, space, SpaceProps } from "styled-system"
import theme from "../../theming/theme"
import {
  CardTypographyBottom,
  CardTypographyTop,
} from "./card-typography.component"
import { CardGradientStyle } from "./card.style"

const Root = styled.div<SpaceProps>`
  ${space};
  text-align: center;
  max-width: 373px;
  box-shadow: 0 -20px 40px -15px rgba(0, 0, 0, 0.05);
  ${CardGradientStyle};
  z-index: 1;
  margin-bottom: ${theme.space.xl};
  margin-left: auto;
  margin-right: auto;
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    width: 373px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

const Box = styled.div<SpaceProps>`
  ${space};
`

const Image = styled(GatsbyImage)<PositionProps>`
  ${position}
`

const CardPlain: AppFunctionComponent<CardProps> = ({
  title,
  paragraph,
  image,
}) => {
  return (
    <Root>
      <Box px={["s", null, "m"]} pt={"zero"} pb={["48px"]}>
        <Image zIndex={3} fluid={image.fluid} />
        <CardTypographyTop>{title}</CardTypographyTop>
        <CardTypographyBottom>{paragraph}</CardTypographyBottom>
      </Box>
    </Root>
  )
}

export default CardPlain
