import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CtaSectionContent } from "./cta-section-content.interface"
import GatsbyImage from "gatsby-image/index"
import Button from "../base/button/button.component"
import styled from "styled-components"
import { space, SpaceProps, typography, TypographyProps } from "styled-system"
import theme from "../../theming/theme"

const Root = styled.div<SpaceProps>`
  ${space};
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 24px 80px;

  @media (min-width: ${theme.breakpoints[1]}) {
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 0 0 120px;
  }
`

const Typography = styled.p<TypographyProps & SpaceProps>`
  ${typography}
  ${space}
`

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${theme.breakpoints[1]}) {
    width: 270px;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  min-width: 100px;

  @media (min-width: ${theme.breakpoints[1]}) {
    width: 50%;
  }
`

const Box = styled.div`
  @media (min-width: ${theme.breakpoints[1]}) {
    padding-top: 120px;
    padding-right: 95px;
    box-sizing: border-box;
    width: 40%;
  }
`

const CtaWithImageOnLeft: AppFunctionComponent<CtaSectionContent> = ({
  image,
  button,
  subText,
  text,
}) => {
  return (
    <Root>
      {image && <Image fluid={image.fluid} />}
      <Box>
        <Typography
          mt={["xl", null, "zero"]}
          fontSize={["heading2-mobile", null, "heading2"]}
          fontWeight={"900"}
          letterSpacing={["heading2-mobile", null, "heading2"]}
          mb={["s", null, "m"]}
        >
          {text}
        </Typography>
        <Typography
          mt={"zero"}
          mb={["xl"]}
          fontSize={["body2", null, "body"]}
          lineHeight={"body"}
        >
          {subText}
        </Typography>
        <StyledButton text={button.text} url={button.url} />
      </Box>
    </Root>
  )
}

export default CtaWithImageOnLeft
