import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import Page from "../components/page/page.component"
import { graphql } from "gatsby"
import PageProps from "../types/page-props.interface"

const PageTemplate: AppFunctionComponent<PageProps> = ({ data: { page } }) => {
  return (
    <Page
      components={page.components}
      image={page.image}
      title={page.seoTitle}
    />
  )
}

export default PageTemplate

export const query = graphql`
  query Page($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }
`
