import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingContent } from "./heading-content.interface"
import { HeadingVariant } from "./heading.enum"
import Heading from "./heading.component"
import HeadingSectionTitle from "./heading-section-title.component"
import HeadingSectionTitleRow from "./heading-section-title-row.component"
import HeadingArrow from "./heading-arrow.component"
import HeadingSmall from "./heading-small.component"
import HeadingPageTitleRow from "./heading-page-title-row.component"

const HeadingSwitch: AppFunctionComponent<HeadingContent> = ({
  variant,
  ...rest
}) => {
  switch (variant) {
    case HeadingVariant.PageTitle:
      return <Heading {...rest} variant={variant} />
    case HeadingVariant.SectionTitle:
      return <HeadingSectionTitle {...rest} variant={variant} />
    case HeadingVariant.SectionTitleRow:
      return <HeadingSectionTitleRow {...rest} variant={variant} />
    case HeadingVariant.PageTitleArrow:
      return <HeadingArrow {...rest} variant={variant} />
    case HeadingVariant.SectionTitleSmall:
      return <HeadingSmall {...rest} variant={variant} />
    case HeadingVariant.PageTitleRow:
      return <HeadingPageTitleRow {...rest} variant={variant} />
    default:
      return <Heading {...rest} variant={variant} />
  }
}

export default HeadingSwitch
