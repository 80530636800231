import { useEffect, useState } from "react"

const useScroll = (initialState: boolean): boolean => {
  const [scrolled, setScrolled] = useState(initialState)

  useEffect(() => {
    const scrollListener = () => {
      setScrolled(window.scrollY > 0)
    }
    if (typeof window !== `undefined`) {
      setScrolled(window.scrollY > 0)
      window.addEventListener("scroll", scrollListener)
    }
    return () => window.removeEventListener("scroll", scrollListener)
  }, [])

  return scrolled
}

export default useScroll
