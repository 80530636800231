import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingWithCardsContent } from "./heading-with-cards-content.interface"
import CardWithGradient from "../cards/card-with-gradient.component"
import styled from "styled-components"
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import Typography from "../typography/typography.component"
import theme from "../../theming/theme"

const Box = styled.div<
  SpaceProps &
    LayoutProps &
    ColorProps &
    TypographyProps &
    PositionProps &
    FlexboxProps
>`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${flexbox}
`

const Card = styled(CardWithGradient)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;

  @media (min-width: ${theme.breakpoints[1]}) {
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  @media (min-width: 930px) {
    &:nth-child(2) {
      top: -120px;
    }

    &:nth-child(3) {
      top: -240px;
    }
  }
`

const HeadingWithCards: AppFunctionComponent<HeadingWithCardsContent> = ({
  heading,
  items,
}) => {
  return (
    <Box bg={"dark"} color={"light"} pt={"128px"}>
      <Box
        bg={"dark"}
        color={"light"}
        maxWidth={"1200px"}
        mx={"auto"}
        px={"s"}
        pb={["200px"]}
      >
        <Typography
          maxWidth={[null, null, "420px", null, "480px"]}
          textAlign={["center", null, "left"]}
          as={"h1"}
          fontWeight={900}
          fontSize={["heading1-mobile", null, "heading1"]}
          letterSpacing={["heading1-mobile", null, "heading1"]}
          lineHeight={["1.3"]}
          mt={"zero"}
          mb={["s"]}
        >
          {heading.title.title}
        </Typography>
        <Typography
          maxWidth={[null, null, "420px", null, "480px"]}
          textAlign={["center", null, "left"]}
          lineHeight={["1.75"]}
          fontSize={["body"]}
          m={"zero"}
        >
          {heading.subtitle?.subtitle}
        </Typography>
      </Box>
      <Box bg={"light"} mb={["-170px"]}>
        <Box maxWidth={"1200px"} mx={"auto"} px={"s"}>
          <Box
            position={"relative"}
            bg={"transparent"}
            top={["-136px", null, "-174px"]}
            ml={"auto"}
            mr={["auto", null, "zero"]}
            maxWidth={["280px", "375px", "890px"]}
            display={[null, null, "flex"]}
            justifyContent={[null, null, "flex-end"]}
          >
            {items.cards.map((item, index) => {
              return (
                <Card
                  key={index}
                  title={item.title}
                  paragraph={item.paragraph}
                  image={item.image}
                  maxWidth={[null, null, "220px", "270px"]}
                />
              )
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HeadingWithCards
