import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { ContactContent } from "./contact-content.interface"
import styled from "styled-components"
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage, { FluidObject } from "gatsby-image"
import text from "../../functions/text"

const Root = styled.div<SpaceProps & LayoutProps & PositionProps>`
  ${space};
  ${layout};
  ${position};
`

const Typography = styled.p<TypographyProps & SpaceProps>`
  ${space};
  ${typography};
`

const Decorator = styled.div`
  width: 8px;
  background-image: linear-gradient(to bottom, #79f2be, #84e780 49%, #f0ef75);
`

const Box = styled.div<SpaceProps & FlexboxProps & LayoutProps & PositionProps>`
  ${space}
  ${flexbox}
  ${layout}
  ${position}
`

const Email = styled.a<ColorProps & SpaceProps & TypographyProps>`
  ${space};
  ${color};
  ${typography};
  text-decoration: none;

  &:hover {
    background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`
const Image = styled(GatsbyImage)<LayoutProps & SpaceProps>`
  ${layout};
  ${space};
`

export const ContactView: AppFunctionComponent<
  ContactContent & {
    image: FluidObject
    imageMobile: FluidObject
  }
> = ({ email, address, info, heading, image, imageMobile }) => {
  const sources = [
    imageMobile,
    {
      ...image,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <Root
      position={"relative"}
      overflow={"hidden"}
      pt={["100px"]}
      maxWidth={"1400px"}
      mx={"auto"}
      px={"s"}
      pb={["xs"]}
    >
      <Box maxWidth={"1200px"} display={[null, null, null, "flex"]} mx={"auto"}>
        <Box width={[null, null, null, "50%"]}>
          <Typography
            as={"h1"}
            fontWeight={"900"}
            mt={["zero", null, "100px"]}
            mb={["s", null, "l"]}
            fontSize={["heading1-mobile", null, "heading1"]}
            letterSpacing={["heading1-mobile", null, "heading1"]}
            lineHeight={["1.3"]}
            textAlign={["center", null, null, "left"]}
          >
            {heading.title.title}
          </Typography>
          {heading.subtitle && (
            <Typography
              textAlign={["center", null, null, "left"]}
              lineHeight={["body"]}
              fontSize={["body"]}
              mt={"zero"}
              mb={["xl"]}
            >
              {heading.subtitle.subtitle}
            </Typography>
          )}
          <Box display={"flex"} mb={["xl", null, null, "zero"]}>
            <Decorator />
            <Box ml={["s", null, "m"]}>
              <Box mt={["zero"]} mb={["s", null, "l"]}>
                <Email
                  color={"light"}
                  href={`mailto:${email}`}
                  fontWeight={"900"}
                  fontSize={["heading3-mobile", null, "heading3"]}
                  letterSpacing={["heading3-mobile", null, "heading3"]}
                >
                  {email}
                </Email>
              </Box>
              <Box
                display={[null, null, null, "flex"]}
                maxWidth={[null, null, null, "500px"]}
              >
                <Typography
                  mt={["zero"]}
                  mb={["xs", null, null, "zero"]}
                  mr={[null, null, null, "l"]}
                  fontSize={["body2", null, "heading3-mobile"]}
                  lineHeight={["body", null, "1.8"]}
                >
                  {text(info)}
                </Typography>
                <Typography
                  mt={["zero"]}
                  mb={["zero"]}
                  fontSize={["body2", null, "heading3-mobile"]}
                  lineHeight={["body", null, "1.8"]}
                >
                  {text(address)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          position={"relative"}
          width={[
            "calc(100% + 48px)",
            null,
            null,
            "calc(50% + 100px)",
            "calc(50% + 200px)",
          ]}
          maxWidth={[null, null, null, "680px"]}
          mr={["-24px", null, null, "-100px", "-200px"]}
          ml={["-24px", null, null, "zero"]}
        >
          <Image fluid={sources} />
        </Box>
      </Box>
    </Root>
  )
}

const Contact: AppFunctionComponent<ContactContent> = ({ ...rest }) => {
  const { contactImage, contactImageMobile } = useStaticQuery(graphql`
    query {
      contactImage: file(name: { eq: "contact" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      contactImageMobile: file(name: { eq: "contact-mobile" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <ContactView
      {...rest}
      image={contactImage.childImageSharp.fluid}
      imageMobile={contactImageMobile.childImageSharp.fluid}
    />
  )
}

export default Contact
