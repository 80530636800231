import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { FeaturesContent } from "./features-content.interface"
import Feature from "./feature.component"

const Features: AppFunctionComponent<FeaturesContent> = ({ features }) => {
  return (
    <div>
      {features.map((item, index) => {
        return <Feature {...item} key={index} />
      })}
    </div>
  )
}

export default Features
