import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CtaSectionContent } from "./cta-section-content.interface"
import { FluidObject } from "gatsby-image"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import Button from "../base/button/button.component"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import theme from "../../theming/theme"
import textFn from "../../functions/text"

const Root = styled.div<SpaceProps>`
  ${space};
  text-align: center;
  position: relative;
`

const Typography = styled.p<TypographyProps & SpaceProps>`
  ${typography}
  ${space}
`

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${theme.breakpoints[1]}) {
    width: 270px;
  }
`

const ImageWrapper = styled.div`
  z-index: 1;
  position: absolute;
  right: 25%;
  width: 100%;
  top: 64px;
  height: 342px;
  @media (min-width: ${theme.breakpoints[1]}) {
    right: 33%;
    top: 120px;
  }
`
const Image = styled(GatsbyImage)`
  height: 342px;
`

const InnerBox = styled.div<LayoutProps & FlexboxProps & SpaceProps>`
  ${space};
  ${flexbox};
  ${layout};
`

const Box = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #2e2e2e, #212121),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;
`

const CtaAboveFooter: AppFunctionComponent<
  CtaSectionContent & { bgImage: FluidObject }
> = ({ button, subText, text, bgImage }) => {
  return (
    <Root px={["s"]} pt={["144px", null, "184px"]} pb={["100px", null, "zero"]}>
      {bgImage && (
        <ImageWrapper>
          <Image fluid={bgImage} objectFit="cover" />
        </ImageWrapper>
      )}
      <Box>
        <InnerBox
          pt={["40px", null, "xl"]}
          pb={["40px", null, "120px"]}
          px={["s", null, "100px", "150px"]}
          display={["flex"]}
          alignItems={["center"]}
          justifyContent={["center"]}
          flexDirection={["column"]}
        >
          <Typography
            mt={"zero"}
            fontSize={["heading1-mobile", null, "heading1"]}
            fontWeight={"900"}
            letterSpacing={["heading1-mobile", null, "heading1"]}
            mb={["s", null, "l"]}
          >
            {textFn(text)}
          </Typography>
          <Typography
            mt={"zero"}
            mb={["xl"]}
            fontSize={"body"}
            lineHeight={"body"}
          >
            {textFn(subText)}
          </Typography>

          <StyledButton text={button.text} url={button.url} />
        </InnerBox>
      </Box>
    </Root>
  )
}

export default CtaAboveFooter
