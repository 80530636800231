import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { TableContent } from "./table-content.interface"
import styled from "styled-components"
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import Typography from "../typography/typography.component"
import text from "../../functions/text"
import theme from "../../theming/theme"

const Root = styled.div<ColorProps & SpaceProps & LayoutProps>`
  ${color}
  ${space}
  ${layout}
`

const Row = styled.div<LayoutProps & GridProps & SpaceProps>`
  ${layout};
  ${grid};
  ${space};
  font-size: 16px;
  font-weight: 900;

  @media (min-width: ${theme.breakpoints[1]}) {
    font-size: 16px;
    font-weight: 300;
  }

  &:nth-child(odd) {
    background-color: #f6f6f6;
  }

  &:nth-child(2) {
    color: #717070;
    display: none;

    @media (min-width: ${theme.breakpoints[1]}) {
      display: grid;
    }
  }
  &:first-child {
    font-size: 20px;
    font-weight: 900;
    background-color: #fff;
    display: none;

    @media (min-width: ${theme.breakpoints[1]}) {
      display: grid;
    }
  }
`

const Cell = styled.div<
  SpaceProps & TypographyProps & LayoutProps & FlexboxProps
>`
  ${space};
  ${typography};
  ${layout};
  ${flexbox};

  &:first-child {
    font-size: 28px;
    font-weight: 900;
    border-bottom: 1px solid #212121;
    @media (min-width: ${theme.breakpoints[1]}) {
      padding-left: 64px;
      border-bottom: 0;
      font-size: inherit;
      font-weight: inherit;
    }
  }
  &:last-child {
    @media (min-width: ${theme.breakpoints[1]}) {
      padding-right: 64px;
    }
  }
`
const Box = styled.div<
  LayoutProps & SpaceProps & FlexboxProps & TypographyProps & ColorProps
>`
  ${layout}
  ${space}
  ${flexbox}
  ${typography}
  ${color}
`

const Table: AppFunctionComponent<TableContent> = ({ texts, table }) => {
  return (
    <Root bg={"light"} color={"dark"} maxWidth={"1200px"} mx={"auto"} py={"xl"}>
      <Box
        pb={"xl"}
        px={"xl"}
        justifyContent={[null, null, "space-between"]}
        alignItems={[null, null, "center"]}
        display={[null, null, "flex"]}
      >
        <Typography
          width={[null, null, "50%"]}
          maxWidth={[null, null, "373px"]}
          fontSize={["heading2-mobile", null, "heading2"]}
          as={"h2"}
          m={"zero"}
          letterSpacing={["heading2-mobile", null, "heading2"]}
        >
          {text(texts.title.title)}
        </Typography>
        <Typography
          maxWidth={[null, null, "480px"]}
          width={[null, null, "50%"]}
          fontSize={["body"]}
          lineHeight={["body"]}
        >
          {text(texts.subtitle?.subtitle)}
        </Typography>
      </Box>
      <Box>
        {table.tableData.map((row, index) => {
          const tableHead = table.tableData[0]
          const tableSubHead = table.tableData[1]
          return (
            <Row
              key={index}
              display={["block", null, "grid"]}
              gridTemplateRows={["repeat(1, 1fr)"]}
              gridTemplateColumns={[`repeat(${row.length}, 1fr)`]}
              py={["32px", null, "zero"]}
            >
              {row.map((cell, cellIndex) => {
                return (
                  <Cell
                    key={cellIndex}
                    pl={["s", null, "l"]}
                    pr={["s", null, "m"]}
                    lineHeight={[null, null, "1"]}
                    py={["10px", null, "20px"]}
                    display={["block", null, "flex"]}
                    alignItems={"center"}
                  >
                    <Box
                      display={[null, null, "none"]}
                      fontSize={"body"}
                      fontWeight={"300"}
                      lineHeight={"2"}
                      letterSpacing={"-0.5px"}
                    >
                      {tableHead[cellIndex]}
                    </Box>
                    <Box
                      display={[null, null, "none"]}
                      fontSize={"body2"}
                      fontWeight={"300"}
                      lineHeight={"1.7"}
                      color={"#717070"}
                    >
                      {tableSubHead[cellIndex]}
                    </Box>
                    {cell}
                  </Cell>
                )
              })}
            </Row>
          )
        })}
      </Box>
    </Root>
  )
}

export default Table
