import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { ImageContent } from "./image-content.interface"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"
import { layout, LayoutProps, space, SpaceProps } from "styled-system"

const Root = styled.div<LayoutProps & SpaceProps>`
  ${layout};
  ${space};
`

const Image: AppFunctionComponent<ImageContent> = ({ image }) => {
  return (
    <Root maxWidth={"1600px"} mx={"auto"}>
      <GatsbyImage fluid={image.fluid} />
    </Root>
  )
}

export default Image
