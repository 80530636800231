import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CtaSectionContent } from "./cta-section-content.interface"
import Button from "../base/button/button.component"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system"
import Typography from "../typography/typography.component"
import GatsbyImage, { FluidObject } from "gatsby-image"

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps & PositionProps>`
  ${space};
  ${layout};
  ${flexbox};
  ${position};
`

const CtaWithBackground: AppFunctionComponent<
  CtaSectionContent & { bgLeft: FluidObject; bgRight: FluidObject }
> = ({ button, subText, text, bgRight, bgLeft }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      py={["120px"]}
      px={"s"}
      position={"relative"}
    >
      <Typography
        textAlign={"center"}
        as={"h2"}
        fontWeight={"900"}
        fontSize={["heading2-mobile", null, "heading2"]}
        letterSpacing={["heading2-mobile", null, "heading2"]}
        lineHeight={["1.43"]}
        mt={"zero"}
        mb={["s", null, "m"]}
      >
        {text}
      </Typography>
      <Typography
        textAlign={"center"}
        fontSize={["body2", null, "body"]}
        mt={"zero"}
        lineHeight={["1.7"]}
        mb={["xl"]}
        maxWidth={[null, null, "480px"]}
      >
        {subText}
      </Typography>
      <Button text={button.text} url={button.url} />
      <Box
        height={["144px", "221px", "303px"]}
        width={["157px", "242px", "331px"]}
        position={"absolute"}
        bottom={"0"}
        left={"0"}
      >
        <GatsbyImage fluid={bgLeft} />
      </Box>
      <Box
        height={["150px", "231px", "316px"]}
        width={["150px", "237px", "324px"]}
        position={"absolute"}
        bottom={"0"}
        right={"0"}
      >
        <GatsbyImage fluid={bgRight} />
      </Box>
    </Box>
  )
}

export default CtaWithBackground
