/**
 * Transforms text coming from external sources to be used in the app.
 *
 * Transformed text tags:
 * - $NBSP$ (non breaking space).
 * - $BR$ (line break).
 *
 * @param content coming from CMS.
 */
const text = (content: string | null = ""): string =>
  (content || "").replace(/\$NBSP\$/gi, "\u00a0").replace(/\$BR\$/gi, "\n")

export default text
