import React from "react"
import Typography from "../typography/typography.component"
import AppFunctionComponent from "@appnroll/app-function-component"
import { PositionProps, SpaceProps, TypographyProps } from "styled-system"

export const CardTypographyTop: AppFunctionComponent<
  TypographyProps & SpaceProps & PositionProps
> = ({ children, className, ...rest }) => {
  return (
    <Typography
      className={className}
      mt={["l"]}
      fontWeight={"900"}
      fontSize={["heading3-mobile", null, "heading3"]}
      letterSpacing={["heading3-mobile", null, "heading3"]}
      mb={["xs"]}
      zIndex={3}
      position={"relative"}
      {...rest}
    >
      {children}
    </Typography>
  )
}

export const CardTypographyBottom: AppFunctionComponent<
  TypographyProps & SpaceProps & PositionProps
> = ({ children, className, ...rest }) => {
  return (
    <Typography
      className={className}
      mt={"zero"}
      fontSize={["body2", null, "body"]}
      lineHeight={"body"}
      zIndex={3}
      position={"relative"}
      {...rest}
    >
      {children}
    </Typography>
  )
}
