import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import GatsbyImage from "gatsby-image"
import styled from "styled-components"
import {
  CardTypographyBottom,
  CardTypographyTop,
} from "./card-typography.component"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system"
import { CardGradientStyle } from "./card.style"

const Root = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space}
  ${layout}
  ${flexbox}
`
const Card = styled.div<SpaceProps & LayoutProps>`
  ${space};
  ${layout};
  box-sizing: border-box;
  ${CardGradientStyle}
`

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space}
  ${layout}
  ${flexbox}
`
const CardsMultiRow: AppFunctionComponent<CardsContent> = ({ cards }) => {
  return (
    <Root px={"s"} display={"flex"} justifyContent={"center"}>
      <Box
        maxWidth={"1200px"}
        mx={[null, "-10px"]}
        display={"flex"}
        flexDirection={["column", "row"]}
        flexWrap={[null, "wrap"]}
      >
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              mx={[null, "10px"]}
              mb={["l"]}
              width={["100%", "calc(50% - 20px)", "calc(33% - 20px)"]}
            >
              <Box px={["s"]} pb={"80px"}>
                <GatsbyImage fluid={card.image.fluid} />
                <CardTypographyTop textAlign={"center"}>
                  {card.title}
                </CardTypographyTop>
                <CardTypographyBottom textAlign={"center"}>
                  {card.paragraph}
                </CardTypographyBottom>
              </Box>
            </Card>
          )
        })}
      </Box>
    </Root>
  )
}

export default CardsMultiRow
