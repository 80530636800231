import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingContent } from "./heading-content.interface"
import styled, { css } from "styled-components"
import { typography, TypographyProps, space, SpaceProps } from "styled-system"
import theme from "../../theming/theme"
import text from "../../functions/text"

const Root = styled.div<{ variant: string }>`
  ${({ variant }) =>
    variant === "section-title" &&
    css`
      text-align: center;
      padding: 80px ${theme.space.s} ${theme.space.xl};
    `};

  ${({ variant }) =>
    variant === "section-title-row" &&
    css`
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 80px 48px ${theme.space.xl};

      @media (min-width: ${theme.breakpoints[2]}) {
        padding: 120px 24px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > * {
          width: 50%;
        }
        & > h1 {
          max-width: 580px;
        }

        & > p {
          max-width: 480px;
        }
      }
    `};
`

const Typography = styled.p<TypographyProps & SpaceProps>`
  ${typography}
  ${space}
`

const Heading: AppFunctionComponent<HeadingContent> = ({
  title,
  subtitle,
  variant = "",
}) => {
  return (
    <Root variant={variant}>
      <Typography
        fontSize={["heading1-mobile", null, "heading1"]}
        as={"h1"}
        m={"zero"}
        letterSpacing={["heading1-mobile", null, "heading1"]}
      >
        {text(title.title)}
      </Typography>
      {subtitle && (
        <Typography fontSize={["body"]} lineHeight={["body"]}>
          {text(subtitle.subtitle)}
        </Typography>
      )}
    </Root>
  )
}

export default Heading
