import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardProps } from "./cards-content.interface"
import GatsbyImage from "gatsby-image"
import { CardTypographyTop } from "./card-typography.component"
import styled from "styled-components"
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import text from "../../functions/text"
import theme from "../../theming/theme"

const Root = styled.div<SpaceProps & LayoutProps & TypographyProps>`
  ${space};
  ${layout};
  ${typography};
  box-sizing: border-box;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #2e2e2e, #212121),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;

  margin-bottom: ${theme.space.xl};
  margin-left: auto;
  margin-right: auto;

  @media (min-width: ${theme.breakpoints[1]}) {
    margin-left: 20px;
    margin-right: 20px;
  }

  @media (min-width: ${theme.breakpoints[2]}) {
    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

const CardSimple: AppFunctionComponent<CardProps> = ({ title, image }) => {
  return (
    <Root
      maxWidth={["373px", "220px", "270px"]}
      width={["100%", "50%", null, "25%"]}
      textAlign={"center"}
    >
      <GatsbyImage fluid={image.fluid} />
      <CardTypographyTop px={"s"} mt={"zero"} mb={["80px", null, "100px"]}>
        {text(title)}
      </CardTypographyTop>
    </Root>
  )
}

export default CardSimple
