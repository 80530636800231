import { graphql, useStaticQuery } from "gatsby"
import NavigationItem from "../types/navigations-item.interface"

export interface QueryResult {
  readonly mainLinks: {
    readonly nodes: NavigationItem[]
  }
  readonly footerLinks: {
    readonly nodes: NavigationItem[]
  }
}

const normalizePath = (item: NavigationItem) => ({
  ...item,
  path: item.path.endsWith("/") ? item.path : `${item.path}/`,
})

const appendPath = (rootPath: string) => (
  link: NavigationItem
): NavigationItem => ({
  ...link,
  path: `${rootPath}${link.path}`,
})

export const useNavigationLinks = (rootPath = "") => {
  const {
    mainLinks: { nodes: mainLinks },
    footerLinks: { nodes: footerLinks },
  } = useStaticQuery<QueryResult>(query)
  return {
    mainLinks: mainLinks.map(appendPath(rootPath)).map(normalizePath),
    footerLinks: footerLinks.map(appendPath(rootPath)).map(normalizePath),
  }
}

const query = graphql`
  query LayoutQuery {
    mainLinks: allContentfulPage(
      filter: { navigationPosition: { in: "top" } }
      sort: { fields: navigationOrder, order: ASC }
    ) {
      nodes {
        navigationName
        path
      }
    }
    footerLinks: allContentfulPage(
      filter: { navigationPosition: { in: "footer" } }
      sort: { fields: navigationOrder, order: ASC }
    ) {
      nodes {
        navigationName
        path
      }
    }
  }
`
