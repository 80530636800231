import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import CardPlain from "./card-plain.component"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system"
import Shape1 from "../../images/shape1.svg"
import Shape2 from "../../images/shape2.svg"
import Shape3 from "../../images/shape3.svg"
import Shape4 from "../../images/shape4.svg"
import Shape5 from "../../images/shape5.svg"

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
  position: relative;
`

const ShapesWrapper = styled.div<LayoutProps & PositionProps>`
  ${layout};
  ${position};
`

const Shape = styled.div<{
  topValue: string
  leftValue: string
  rotateValue: string
  zIndexValue: string
}>`
  position: absolute;
  top: ${({ topValue }) => topValue}%;
  left: ${({ leftValue }) => leftValue}%;
  transform: rotate(${({ rotateValue }) => rotateValue}deg);
  z-index: ${({ zIndexValue }) => (zIndexValue > "5" ? "1" : "0")};
`

const CardsPlain: AppFunctionComponent<CardsContent> = ({ cards, shapes }) => {
  return (
    <Box>
      {shapes && (
        <ShapesWrapper
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          <Shape
            topValue={(Math.random() * 100).toFixed(0)}
            leftValue={(Math.random() * 100).toFixed(0)}
            rotateValue={(Math.random() * 360).toFixed(0)}
            zIndexValue={(Math.random() * 10).toFixed(0)}
          >
            <Shape1 />
          </Shape>
          <Shape
            topValue={(Math.random() * 100).toFixed(0)}
            leftValue={(Math.random() * 100).toFixed(0)}
            rotateValue={(Math.random() * 360).toFixed(0)}
            zIndexValue={(Math.random() * 10).toFixed(0)}
          >
            <Shape2 />
          </Shape>
          <Shape
            topValue={(Math.random() * 100).toFixed(0)}
            leftValue={(Math.random() * 100).toFixed(0)}
            rotateValue={(Math.random() * 360).toFixed(0)}
            zIndexValue={(Math.random() * 10).toFixed(0)}
          >
            <Shape3 />
          </Shape>
          <Shape
            topValue={(Math.random() * 100).toFixed(0)}
            leftValue={(Math.random() * 100).toFixed(0)}
            rotateValue={(Math.random() * 360).toFixed(0)}
            zIndexValue={(Math.random() * 10).toFixed(0)}
          >
            <Shape4 />
          </Shape>
          <Shape
            topValue={(Math.random() * 100).toFixed(0)}
            leftValue={(Math.random() * 100).toFixed(0)}
            rotateValue={(Math.random() * 360).toFixed(0)}
            zIndexValue={(Math.random() * 10).toFixed(0)}
          >
            <Shape5 />
          </Shape>
        </ShapesWrapper>
      )}
      <Box p={"s"} display={["block", "flex"]} justifyContent={"center"}>
        {cards.map((card, index) => {
          const { image, title, paragraph } = card
          return (
            <CardPlain
              image={image}
              paragraph={paragraph}
              title={title}
              key={index}
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default CardsPlain
