import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import { CardVariant } from "./cards.enum"
import CardsPlain from "./cards-plain.components"
import CardsWithGradient from "./cards-with-gradient.components"
import { graphql, useStaticQuery } from "gatsby"
import CardsAlignLeft from "./cards-align-left.component"
import CardsWithDisclaimerSimple from "./cards-with-disclaimer-simple.component"
import CardsWithDisclaimer from "./cards-with-disclaimer.component"
import CardsMultiRow from "./cards-multi-row.component"

const CardsSwitch: AppFunctionComponent<CardsContent> = ({
  variant,
  ...rest
}) => {
  const { cardsBG } = useStaticQuery(graphql`
    query CardsBG {
      cardsBG: file(name: { eq: "cards-bg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  switch (variant) {
    case CardVariant.Plain:
      return <CardsPlain variant={variant} {...rest} />
    case CardVariant.WithGradient:
      return (
        <CardsWithGradient
          imageBG={cardsBG.childImageSharp.fluid}
          variant={variant}
          {...rest}
        />
      )
    case CardVariant.AlignLeft:
      return <CardsAlignLeft variant={variant} {...rest} />
    case CardVariant.WithDisclaimerSimple:
      return <CardsWithDisclaimerSimple variant={variant} {...rest} />
    case CardVariant.WithDisclaimer:
      return <CardsWithDisclaimer variant={variant} {...rest} />
    case CardVariant.MultiRow:
      return <CardsMultiRow variant={variant} {...rest} />
    default:
      return null
  }
}

export default CardsSwitch
