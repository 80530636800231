import AppFunctionComponent from "@appnroll/app-function-component"
import React, { useContext } from "react"
import AppnrollLogo from "../../../images/appnroll.svg"
import NavigationItem from "../../../types/navigations-item.interface"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"
import LogoIcon from "../../../images/logo-bg.svg"
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"
import { SiteConfigContext } from "../../../context/site-config.context"
import theme from "../../../theming/theme"
import localPath from "../../../functions/local-path"
import GatsbyImage, { FluidObject } from "gatsby-image"

interface SocialLinks {
  twitterLink: string
  facebookLink: string
  instagramLink: string
  linkedinLink: string
  youtubeLink: string
  appnrollLink: string
  privacyPolicyLink: {
    path: string
  }
}

interface SocialIcons {
  ytIcon: FluidObject
  fbIcon: FluidObject
  ttIcon: FluidObject
  igIcon: FluidObject
  inIcon: FluidObject
}

interface Props {
  links: NavigationItem[]
}

const Root = styled.footer<ColorProps & SpaceProps & TypographyProps>`
  ${color};
  ${space};
  ${typography};
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`

const Logo = styled(LogoIcon)<LayoutProps & SpaceProps>`
  ${layout};
  ${space};
`

const NavLink = styled(Link)<ColorProps & TypographyProps & SpaceProps>`
  ${color};
  ${typography};
  ${space};
  text-decoration: none;

  &:hover {
    background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const Nav = styled.nav<LayoutProps & FlexboxProps & SpaceProps>`
  ${layout};
  ${flexbox};
  ${space};
`

const SocialLink = styled.a<SpaceProps>`
  ${space};

  margin-right: ${theme.space.m};

  &:last-child {
    margin-right: 0;
  }
`

const SocialIcon = styled(GatsbyImage)<SpaceProps & LayoutProps>`
  ${layout};
  ${space};
`

const SocialBox = styled.div<LayoutProps & SpaceProps & FlexboxProps>`
  ${layout};
  ${space};
  ${flexbox};
`

const Typography = styled.p<
  TypographyProps & SpaceProps & LayoutProps & FlexboxProps
>`
  ${typography};
  ${space};
  ${layout};
  ${flexbox};
`

const Hr = styled.hr<SpaceProps & ColorProps & LayoutProps>`
  ${space};
  ${color};
  ${layout};
`

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
`

const ANRLink = styled.a<FlexboxProps & LayoutProps>`
  ${flexbox};
  ${layout};
`

const PrivacyPolicyLink = styled(Link)<ColorProps>`
  ${color};
  text-decoration: none;
`

const FooterView: AppFunctionComponent<Props & SocialLinks & SocialIcons> = ({
  links,
  appnrollLink,
  instagramLink,
  twitterLink,
  youtubeLink,
  facebookLink,
  linkedinLink,
  privacyPolicyLink,
  ytIcon,
  ttIcon,
  igIcon,
  fbIcon,
  inIcon,
}) => {
  const currentYear = new Date().getFullYear()
  return (
    <Root
      color={"light"}
      bg={"dark"}
      pb={["95px", null, "l"]}
      pt={["77px", null, "xl"]}
      px={["s"]}
      textAlign={["center"]}
    >
      <Box
        maxWidth={"1200px"}
        display={["block", null, "flex"]}
        flexDirection={"column"}
        mx={"auto"}
      >
        <Box display={["block", null, null, "flex"]}>
          <Link to={"/"}>
            <Logo
              width={["footerLogoMobileWidth", null, "footerLogoWidth"]}
              height={["footerLogoMobileHeight", null, "footerLogoHeight"]}
              mr={[null, null, null, "84px"]}
            />
          </Link>
          <div>
            <Typography
              mt={["s", null, "zero"]}
              mb={["xs"]}
              fontSize={["body"]}
              lineHeight={"body"}
            >
              Znajdź nas na:
            </Typography>
            <SocialBox mb={["20px"]} display={"flex"} justifyContent={"center"}>
              {instagramLink !== " " && (
                <SocialLink href={instagramLink}>
                  <SocialIcon width={["24px"]} fluid={igIcon} />
                </SocialLink>
              )}
              {twitterLink !== " " && (
                <SocialLink href={twitterLink}>
                  <SocialIcon width={["24px"]} fluid={ttIcon} />
                </SocialLink>
              )}
              {youtubeLink !== " " && (
                <SocialLink href={youtubeLink}>
                  <SocialIcon width={["24px"]} fluid={ytIcon} />
                </SocialLink>
              )}
              {facebookLink !== " " && (
                <SocialLink href={facebookLink}>
                  <SocialIcon width={["24px"]} fluid={fbIcon} />
                </SocialLink>
              )}
              {linkedinLink !== " " && (
                <SocialLink href={linkedinLink}>
                  <SocialIcon width={["24px"]} fluid={inIcon} />
                </SocialLink>
              )}
            </SocialBox>
          </div>
          <Box flexGrow={[null, null, 1]} />
          <Nav
            display={"flex"}
            flexDirection={["column", null, null, "row"]}
            mb={["30px", null, "zero"]}
          >
            {links.map((link, index) => {
              const { path, navigationName } = link
              return (
                <NavLink
                  key={index}
                  to={localPath(path)}
                  color={"light"}
                  ml={[null, null, null, "xl"]}
                  fontWeight={"900"}
                  fontSize={["body"]}
                  lineHeight={["big"]}
                  letterSpacing={"-0.5px"}
                  mt={["10px", null, null, "zero"]}
                  mb={["10px", null, null, "zero"]}
                >
                  {navigationName}
                </NavLink>
              )
            })}
          </Nav>
        </Box>
        <Box>
          <Hr width={"100%"} />
          <Box
            display={["block", null, "flex"]}
            px={["m", null, "zero"]}
            pt={["s"]}
          >
            <Typography
              fontSize={"body"}
              lineHeight={"body"}
              mt={"zero"}
              mb={"xs"}
            >
              © {currentYear} Wszelkie prawa zastrzeżone.
            </Typography>
            <Box flexGrow={[null, null, 1]} />
            <Typography
              fontSize={"body"}
              lineHeight={"body"}
              mt={"zero"}
              mb={"xs"}
              mr={["zero", null, "96px"]}
            >
              <PrivacyPolicyLink
                color={"light"}
                to={localPath(privacyPolicyLink.path)}
              >
                Polityka prywatności
              </PrivacyPolicyLink>
            </Typography>
            <Typography
              fontSize={"body"}
              lineHeight={"body"}
              mt={"zero"}
              mb={"xs"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              Made by &nbsp;
              <ANRLink
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                href={appnrollLink}
                title="App'n'roll"
              >
                <AppnrollLogo />
              </ANRLink>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Root>
  )
}

const Footer: AppFunctionComponent<Props> = (props) => {
  const config = useContext(SiteConfigContext)
  const { fbIcon, ttIcon, inIcon, ytIcon, igIcon } = useStaticQuery(footerQuery)
  return (
    <FooterView
      {...config}
      {...props}
      fbIcon={fbIcon.childImageSharp.fluid}
      igIcon={igIcon.childImageSharp.fluid}
      ttIcon={ttIcon.childImageSharp.fluid}
      ytIcon={ytIcon.childImageSharp.fluid}
      inIcon={inIcon.childImageSharp.fluid}
    />
  )
}

export default Footer

const footerQuery = graphql`
  query footerIcons {
    fbIcon: file(name: { eq: "fb" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ttIcon: file(name: { eq: "tt" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    inIcon: file(name: { eq: "in" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    igIcon: file(name: { eq: "ig" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ytIcon: file(name: { eq: "yt" }, extension: { eq: "png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
