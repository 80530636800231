import AppFunctionComponent from "@appnroll/app-function-component"
import React, { useState } from "react"
import styled from "styled-components"
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  typography,
  TypographyProps,
  border,
  BorderProps,
} from "styled-system"
import Logo from "../../../images/logo.svg"
import useScroll from "../../../hooks/useScroll"
import NavigationItem from "../../../types/navigations-item.interface"
import { Link } from "gatsby"
import localPath from "../../../functions/local-path"
import HamburgerIcon from "../../../images/hamburger.svg"
import LogoDarkIcon from "../../../images/logo-dark.svg"
import ScrollLock from "react-scrolllock"
import CloseIcon from "../../../images/close.svg"
import { motion, AnimatePresence } from "framer-motion"

interface Props {
  links: NavigationItem[]
}

const HeaderRoot = styled.header<SpaceProps & LayoutProps & PositionProps>`
  ${space};
  ${layout};
  ${position};
  width: 100%;
  z-index: 10;
`

const ContentWrapper = styled.div<SpaceProps & ColorProps>`
  ${space};
  ${color};
  width: 100%;
  height: 100px;
  transition: background-color 0.3s ease-in;
`

const Box = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
`

const Hamburger = styled.button<LayoutProps & ColorProps & BorderProps>`
  ${layout};
  ${color};
  ${border};
`

const NavLink = styled(Link)<SpaceProps & TypographyProps & ColorProps>`
  ${space};
  ${typography};
  ${color};
  transition: color 0.3s ease-in;
  text-decoration: none;

  &.active {
    background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const MobileNavLink = styled(Link)<SpaceProps & TypographyProps & ColorProps>`
  ${space};
  ${typography};
  ${color};
  text-decoration: none;
`

const Root = styled(motion.div)`
  position: fixed;
  z-index: 20;
  width: 100vw;
  height: 100vh;
`

const MobileMenuList = styled.ul<SpaceProps>`
  list-style: none;
  ${space};
`

const MobileMenuListItem = styled.li<SpaceProps>`
  ${space};
`

const MobileWrapper = styled.div<
  ColorProps & FlexboxProps & SpaceProps & LayoutProps
>`
  ${flexbox};
  ${space};
  ${layout};
  background-image: linear-gradient(to right, #79f2be, #84e780 49%, #f0ef75);
  box-sizing: border-box;
`

const LogoDark = styled(LogoDarkIcon)<SpaceProps>`
  ${space}
`

const CloseWrapper = styled.div<LayoutProps & FlexboxProps>`
  ${layout};
  ${flexbox};
`

const CloseButton = styled.button<ColorProps & BorderProps>`
  ${color};
  ${border};
`

const Header: AppFunctionComponent<Props> = ({ links = [] }) => {
  const scrolled = useScroll(false)
  const [scroll, setScroll] = useState(false)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(!open)
    setScroll(!scroll)
  }

  return (
    <>
      <HeaderRoot data-testid={"header"} position={"fixed"}>
        <ContentWrapper bg={scrolled ? "light" : "dark"}>
          <Box
            justifyContent={"space-between"}
            alignItems={"center"}
            mx={"auto"}
            my={"0"}
            maxWidth={"1200px"}
            display={"flex"}
            height={"100px"}
            px={"s"}
          >
            <Link to="/">
              <Logo />
            </Link>
            <Box as={"nav"} display={["none", "block"]}>
              {links.map((link, index) => {
                return (
                  <NavLink
                    ml={"l"}
                    color={scrolled ? "dark" : "light"}
                    fontSize={"body"}
                    fontWeight={"bold"}
                    activeClassName="active"
                    key={index}
                    to={localPath(link.path)}
                  >
                    {link.navigationName}
                  </NavLink>
                )
              })}
            </Box>
            <Hamburger
              display={["block", "none"]}
              bg={"transparent"}
              border={"0"}
              onClick={handleOpen}
            >
              <HamburgerIcon />
            </Hamburger>
          </Box>
        </ContentWrapper>
      </HeaderRoot>
      <AnimatePresence>
        {open && (
          <Root
            initial={{ x: "-100%" }}
            animate={{ x: "0" }}
            exit={{ x: "-100%" }}
          >
            <ScrollLock isActive={scroll}>
              <MobileWrapper
                display={"flex"}
                p={"s"}
                width={"100vw"}
                height={"100vh"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <CloseWrapper
                  display={"flex"}
                  justifyContent={"flex-end"}
                  width={"100%"}
                  height={"64px"}
                >
                  <CloseButton
                    bg={"transparent"}
                    border={"0"}
                    onClick={handleOpen}
                  >
                    <CloseIcon />
                  </CloseButton>
                </CloseWrapper>
                <Link to={"/"}>
                  <LogoDark mb={"80px"} />
                </Link>
                <MobileMenuList p={"zero"}>
                  {links.map((link, index) => {
                    return (
                      <MobileMenuListItem key={index} p={"zero"} mb={"l"}>
                        <MobileNavLink
                          color={"dark"}
                          fontSize={"body"}
                          fontWeight={"bold"}
                          to={localPath(link.path)}
                        >
                          {link.navigationName}
                        </MobileNavLink>
                      </MobileMenuListItem>
                    )
                  })}
                </MobileMenuList>
              </MobileWrapper>
            </ScrollLock>
          </Root>
        )}
      </AnimatePresence>
    </>
  )
}

export default Header
