import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CtaSectionContent } from "./cta-section-content.interface"
import { CtaSectionVariant } from "./cta-section.enum"
import CtaAboveFooter from "./cta-above-footer.component"
import CtaWithImageOnLeft from "./cta-with-image-on-left.component"
import { graphql, useStaticQuery } from "gatsby"
import CtaWithShapes from "./cta-section-with-shapes.component"
import CtaWithBackground from "./cta-with-background.component"

const CtaSwitch: AppFunctionComponent<CtaSectionContent> = ({
  variant,
  ...rest
}) => {
  const {
    ctaBG,
    shape1Big,
    shape2Big,
    ctaLeft,
    ctaRight,
  } = useStaticQuery(graphql`
    query CtaBG {
      ctaBG: file(name: { eq: "cta-bg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ctaLeft: file(name: { eq: "cta-left" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ctaRight: file(name: { eq: "cta-right" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      shape1Big: file(name: { eq: "shape1Big" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      shape2Big: file(name: { eq: "shape2Big" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  switch (variant) {
    case CtaSectionVariant.AboveFooter:
      return (
        <CtaAboveFooter
          {...rest}
          variant={variant}
          bgImage={ctaBG.childImageSharp.fluid}
        />
      )
    case CtaSectionVariant.WithImageOnLeft:
      return <CtaWithImageOnLeft {...rest} variant={variant} />
    case CtaSectionVariant.WithBackground:
      return (
        <CtaWithBackground
          {...rest}
          variant={variant}
          bgLeft={ctaLeft.childImageSharp.fluid}
          bgRight={ctaRight.childImageSharp.fluid}
        />
      )
    case CtaSectionVariant.WithShapes:
      return (
        <CtaWithShapes
          shape1={shape1Big.childImageSharp.fluid}
          shape2={shape2Big.childImageSharp.fluid}
          {...rest}
          variant={variant}
        />
      )
    default:
      return null
  }
}

export default CtaSwitch
