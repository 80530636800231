import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeroContent } from "./hero-content.interface"
import HeroUI from "./hero-ui.component"
import { graphql, useStaticQuery } from "gatsby"

const Hero: AppFunctionComponent<HeroContent> = (props) => {
  const { videoMp4, videoWebm } = useStaticQuery(graphql`
    query VideoHero {
      videoMp4: file(name: { eq: "hero-mp4" }) {
        publicURL
      }
      videoWebm: file(name: { eq: "hero-webm" }) {
        publicURL
      }
    }
  `)
  return <HeroUI {...props} videoMp4={videoMp4} videoWebm={videoWebm} />
}

export default Hero
