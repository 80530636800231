import { Link } from "gatsby"
import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import CookieIcon from "../../images/cookie.svg"
import { ButtonStyle } from "../base/button/button.style"
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system"

const Container = styled.aside<{ active: boolean } & ColorProps>`
  ${color};
  position: fixed;
  bottom: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 150ms;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      pointer-events: auto;
      z-index: 11;
    `}
`

const Content = styled.div<SpaceProps & LayoutProps & FlexboxProps>`
  ${space};
  ${layout};
  ${flexbox};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 45rem) {
    flex-direction: row;
  }
`

const Info = styled.p<TypographyProps & SpaceProps>`
  ${typography};
  ${space};
`

const PolicyLink = styled(Link)<ColorProps>`
  ${color};
`

const Cookie = styled(CookieIcon)<SpaceProps & LayoutProps>`
  ${space};
  ${layout};
  flex-shrink: 0;
`

const Button = styled.button<
  ColorProps &
    BackgroundProps &
    FlexboxProps &
    TypographyProps &
    LayoutProps &
    BorderProps
>`
  ${ButtonStyle};
  ${color};
  ${border};
  ${background};
  ${flexbox};
  ${typography};
  ${layout};
`

const Box = styled.div<LayoutProps>`
  ${layout};
`

interface Props {
  active?: boolean
  termsLink: string
  onAccept?: () => void
}

const CookiesView: FunctionComponent<Props> = ({
  active = true,
  termsLink,
  onAccept = () => null,
}) => {
  return (
    <Container bg={"light"} active={active}>
      <Content py={["xs"]} px={["s"]} mx={"auto"} maxWidth={"1200px"}>
        <Box display={"flex"} maxWidth={"600px"}>
          <Cookie
            mr={["8px", null, "s"]}
            width={["35px", null, "48px"]}
            height={["35px", null, "48px"]}
          />
          <Info
            mt={"0"}
            fontSize={["caption", null, "body2"]}
            lineHeight={["1.33"]}
          >
            Jestem świadomy/a, że informacje o administratorze i prawach do
            moich danych osobowych oraz o polityce cookies są dostępne w{" "}
            <PolicyLink color={"dark"} to={termsLink}>
              Polityce Prywatności
            </PolicyLink>{" "}
            Huby Mobilności sp. z o.o."
          </Info>
        </Box>
        <Button
          border={"0"}
          color={"dark"}
          fontWeight={"bold"}
          fontSize={"body"}
          letterSpacing={"button"}
          display={"flex"}
          width={["100%", null, "270px"]}
          onClick={onAccept}
        >
          rozumiem
        </Button>
      </Content>
    </Container>
  )
}

export default CookiesView
