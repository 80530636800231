import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingContent } from "./heading-content.interface"
import styled from "styled-components"
import {
  typography,
  TypographyProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
} from "styled-system"
import { renderAst } from "../../functions/renderer-ast"

const Root = styled.div<TypographyProps & SpaceProps & LayoutProps>`
  ${typography};
  ${space};
  ${layout};
`

const Typography = styled.div<TypographyProps & SpaceProps & LayoutProps>`
  ${typography};
  ${space};
  ${layout};
`

const HeadingSectionTitle: AppFunctionComponent<HeadingContent> = ({
  title,
  subtitle,
}) => {
  return (
    <Root
      textAlign={"center"}
      pt={"80px"}
      px={"s"}
      pb={"xl"}
      maxWidth={"990px"}
      mx={"auto"}
    >
      <Typography
        fontSize={["heading1-mobile", null, "heading1"]}
        m={"zero"}
        fontWeight={"900"}
        letterSpacing={["heading1-mobile", null, "heading1"]}
      >
        {renderAst(title.childMarkdownRemark.htmlAst)}
      </Typography>
      {subtitle && (
        <Typography
          fontSize={["body"]}
          lineHeight={["body"]}
          maxWidth={"580px"}
          mx={"auto"}
        >
          {renderAst(subtitle.childMarkdownRemark.htmlAst)}
        </Typography>
      )}
    </Root>
  )
}

export default HeadingSectionTitle
