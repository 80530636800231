import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardProps } from "./cards-content.interface"
import GatsbyImage from "gatsby-image/index"
import styled from "styled-components"
import { layout, LayoutProps, space, SpaceProps } from "styled-system"
import theme from "../../theming/theme"
import {
  CardTypographyBottom,
  CardTypographyTop,
} from "./card-typography.component"

const Root = styled.div<SpaceProps & LayoutProps>`
  ${space};
  ${layout};
  text-align: center;
  position: relative;
  z-index: 2;
  max-width: 373px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #2e2e2e, #212121),
    linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;

  margin-bottom: ${theme.space.xl};
  margin-left: auto;
  margin-right: auto;
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${theme.breakpoints[0]}) {
    width: 373px;
    margin-bottom: 0;
    margin-left: 20px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }
`

const Box = styled.div<SpaceProps>`
  ${space};
`

const CardWithGradient: AppFunctionComponent<CardProps & LayoutProps> = ({
  title,
  paragraph,
  image,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <GatsbyImage fluid={image.fluid} />
      <Box px={["s", null, "m"]} pt={"zero"} pb={["48px"]}>
        <CardTypographyTop>{title}</CardTypographyTop>
        <CardTypographyBottom>{paragraph}</CardTypographyBottom>
      </Box>
    </Root>
  )
}

export default CardWithGradient
