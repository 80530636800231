import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { CardsContent } from "./cards-content.interface"
import styled from "styled-components"
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system"
import CardWithGradient from "./card-with-gradient.component"
import theme from "../../theming/theme"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import { FluidObject } from "gatsby-image"

const Root = styled.div<
  SpaceProps & LayoutProps & FlexboxProps & PositionProps
>`
  ${space};
  ${layout};
  ${flexbox};
  ${position};
`

const ImageWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 25%;
  width: 100%;
  top: 0;
  height: 342px;
  @media (min-width: ${theme.breakpoints[1]}) {
    left: 33%;
  }
`
const Image = styled(GatsbyImage)`
  height: 342px;
`

export const CardsWithGradient: AppFunctionComponent<
  CardsContent & { imageBG: FluidObject }
> = ({ cards, imageBG }) => {
  return (
    <Root
      position={"relative"}
      overflow={"hidden"}
      pt={["xl"]}
      px={"s"}
      display={["block", "flex"]}
      justifyContent={"center"}
    >
      <ImageWrapper>
        <Image fluid={imageBG} objectFit="cover" />
      </ImageWrapper>
      {cards.map((card, index) => {
        const { image, title, paragraph } = card
        return (
          <CardWithGradient
            image={image}
            paragraph={paragraph}
            title={title}
            key={index}
          />
        )
      })}
    </Root>
  )
}

export default CardsWithGradient
