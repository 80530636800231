import React from "react"
import AppFunctionComponent from "@appnroll/app-function-component"
import { HeadingContent } from "./heading-content.interface"
import styled from "styled-components"
import {
  typography,
  TypographyProps,
  space,
  SpaceProps,
  LayoutProps,
  layout,
  FlexboxProps,
  flexbox,
} from "styled-system"
import { renderAst } from "../../functions/renderer-ast"

const Root = styled.div<
  TypographyProps & SpaceProps & LayoutProps & FlexboxProps
>`
  ${typography};
  ${space};
  ${layout};
  ${flexbox};
`

const Typography = styled.div<TypographyProps & SpaceProps & LayoutProps>`
  ${typography};
  ${space};
  ${layout};

  p {
    margin: 0;
  }
`

const HeadingSectionTitleRow: AppFunctionComponent<HeadingContent> = ({
  title,
  subtitle,
}) => {
  return (
    <Root
      justifyContent={[null, null, "space-between"]}
      alignItems={[null, null, "center"]}
      display={[null, null, "flex"]}
      maxWidth={"1200px"}
      mx={"auto"}
      textAlign={["center", null, "left"]}
      px={["48px", null, "24px"]}
      pt={["80px", null, "120px"]}
    >
      <Typography
        width={[null, null, "50%"]}
        maxWidth={[null, null, "580px"]}
        fontSize={["heading1-mobile", null, "heading1"]}
        m={"zero"}
        fontWeight={["900"]}
        letterSpacing={["heading1-mobile", null, "heading1"]}
      >
        {renderAst(title.childMarkdownRemark.htmlAst)}
      </Typography>
      {subtitle && (
        <Typography
          maxWidth={[null, null, "480px"]}
          width={[null, null, "50%"]}
          fontSize={["body"]}
          lineHeight={["body"]}
        >
          {renderAst(subtitle.childMarkdownRemark.htmlAst)}
        </Typography>
      )}
    </Root>
  )
}

export default HeadingSectionTitleRow
