import { css } from "styled-components"

export const CardGradientStyle = css`
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(
    to bottom,
    #ededed,
    rgba(255, 255, 255, 0)
  );
  background-image: linear-gradient(to bottom, #f6f6f6, #ffffff 100%),
    linear-gradient(to bottom, #ededed, rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;
`
